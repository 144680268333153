@import '../../assets/style/colors';
@import '../../assets/style/fonts';

.end-call-dialog {
    .end-call-dialog__title {
        text-align: center;
        border-bottom: 1px solid #e6e8f0;
        margin-bottom: 20px;
    }

    .MuiInputLabel-formControl {
        transform: translate(16px, 15px) scale(1);
    }

    .MuiInputLabel-shrink {
        transform: translate(12px, 4px) scale(0.75);

        + .MuiInputBase-root {
            .MuiSelect-select.MuiSelect-select {
                padding-top: 1rem;
            }
        }
    }

    .end-call-dialog__option {
        display: flex;
        flex-direction: row;
        font-family: 'MaisonNeueDemi';
        font-size: 12px;
        .MuiCheckbox-root {
            padding: 8px;
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .text {
            align-self: center;
        }

        svg {
            color: #82c780;
        }
        &.space-after {
            margin-bottom: 1.5rem;
        }
    }

    .end-call-dialog__reason {
        border: 1px solid $darkgreen;
        border-radius: 8px;
        padding: 16px;
        margin: 16px 0;
        border: 0;
        padding: 0;

        &.hide {
            display: none;
        }
    }

    .hide-component {
        display: none;
    }

    .end-call-dialog__content {
        .MuiFormControl-root {
            width: 100%;
        }
    }

    .end-call-dialog__actions {
        display: flex;
        align-self: center;
    }
}
