@import './_breakspoints.scss';
@import './_colors.scss';
@import './_fonts.scss';
@import './_buttons.scss';
@import './_mixins.scss';

body,
html {
    color: $darkgreen;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

strong,
b {
    font-family: $mosh-font-family;
    font-weight: normal;
    color: $darkgreen;
}

h1,
h2,
h4 {
    @include h-mixin;
}

h2 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h3 {
    font-family: $mosh-font-family;
    font-size: 16px;
    margin-top: 0;
    color: $darkgreen;
    @media (min-width: 960px) {
        font-size: 20px;
    }
}

h5,
button.MuiButtonBase-root,
input[type='submit'],
input[type='button'] {
    font-family: $mosh-font-family;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 14px;
}

button[class^='MuiButton-text'] {
    text-transform: none !important;
}

div[class^='mchatInputContainer'] {
    > div {
        border: 2px solid #e6e8ec;
    }
}

div[class^='makeStyles-background'] {
    padding-top: 130px;
    padding-bottom: 50px;
}

div[class^='div-root'] div[class^='makeStyles-topLogo'] {
    background-color: white;
    margin: auto;
    padding: 15px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 960px) {
        padding: 20px;
    }
}

header {
    box-shadow: none !important;
}

.ck-editor__editable {
    max-height: 250px !important;
    min-height: 120px !important;
}

.ck.ck-editor {
    margin-top: 15px;
}

.MuiDialog-paperWidthXs {
    max-width: 400px;

    .MuiDialogContent-root {
        padding: 8px 24px;
    }

    button {
        max-width: 230px;
        margin-left: auto;
        margin-right: auto;
    }
}

.loading-spinner--large {
    margin-bottom: 20px;
}

.loading-spinner__container {
    height: 100%;
    padding: 80px 20px 30px 20px;
}

div.identity,
span.identity {
    padding: 6px;
    padding-right: 8px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    display: inline-flex;

    p,
    span {
        font-size: 12px;
    }

    svg {
        width: 17px;
        height: 17px;
        margin-right: 2px;
        margin-left: 0;
    }
}

main {
    overflow-y: scroll !important;
    padding-bottom: 0 !important;
}

.text-align-center {
    text-align: center;
}

div.MuiPaper-rounded {
    border-radius: 15px;
}

div.MuiDialog-paperWidthSm {
    max-width: 520px;
}

.input_grey textarea {
    font-size: 14px;
    border: 1px solid #e3e3e3;
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 10px;
    min-height: 50px;
    &::placeholder {
        color: #868686;
    }
}

// Flex-helper
$flex-max: 12;
@mixin flex-x-list {
    @for $i from 1 through $flex-max {
        .flex-#{$i} {
            flex: $i;
        }
    }
}

// Margin helper
$margin-max: 12;
@mixin margin-x-list {
    @for $i from 1 through $margin-max {
        .margin-#{$i} {
            margin: #{$i}rem;
        }
    }
}

@include flex-x-list;
