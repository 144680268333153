.consult-info__container {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border: 0;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 10px;
    @media (min-width: 960px) {
        padding: 15px;
        margin-top: 40px;
        margin-bottom: 50px;
    }

    .consult-info__section {
        padding: 10px 10px 20px;

        &:last-child {
            padding-bottom: 10px;
        }

        @media (min-width: 960px) {
            padding: 10px 15px 25px;
            &:last-child {
                padding-bottom: 15px;
            }
        }

        h3 {
            margin-top: 3px;
            margin-bottom: 0;
        }
    }

    .consult-info__view-history {
        padding: 10px;
        padding-top: 5px;
        @media (min-width: 960px) {
            padding: 15px;
        }
        button.btn--reg {
            display: block;
            background-image: url('../../assets/icons/arrow-right-tail-dark.svg');
            background-repeat: no-repeat;
            background-position: center right;
            padding: 0;
            padding-right: 21px;
            background-size: 14px;
            text-decoration: underline;
            width: auto;
            height: auto;
            background-color: transparent;
        }
    }

    .consult-info__section-label {
        font-size: 12px;
        color: #999999;
        @media (min-width: 960px) {
            font-size: 13px;
        }
    }
}
