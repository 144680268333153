@import '../../assets/style/colors';

.MuiSnackbar-root {
    bottom: 140px !important;
}

.unread-notification__container {
    background: rgba(130, 199, 128);
    border-radius: 8px;
    align-self: center;
    display: flex;
    z-index: 9999;

    .unread-notification__inner-wrapper {
        padding: 8px 16px;
        display: flex;
    }

    .MuiSvgIcon-root {
        color: $darkgreen;
    }

    .unread-notification__message {
        align-self: center;
        color: $darkgreen;
        margin-left: 8px;
    }
}
