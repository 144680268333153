@import '../../assets/style/fonts';

.doctor-notification-block {
    margin-top: 32px;

    .doctor-notification-block__title {
        font-size: 18px;
        font-family: $font-clear-face;
    }

    .doctor-notification-block__content {
        margin-top: 16px;
    }
}
