@import './_colors.scss';
@import './_fonts.scss';

@mixin outer-container {
    border-radius: 20px;
    @media (min-width: 960px) {
        border: 1px solid #e6e8ec;
    }
}

@mixin h-mixin {
    font-family: $font-clear-face;
    color: $darkgreen;
    font-size: 35px;
    letter-spacing: -0.02em;
    font-weight: unset;
    padding-top: 0;
    margin-bottom: 0;
}
