@import '../../assets/style/_colors.scss';
@import '../../assets/style/_fonts.scss';
@import '../../assets/style/_breakspoints.scss';

div.MuiToolbar-regular {
    min-height: 66px;
}

.doctor-end-screen-dialog {
    background: $darkgreen;
    text-align: center;
    .inner-block {
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        align-self: center;
        display: flex;
        a {
            flex: 1;
        }

        .logo {
            flex: 1;
            display: flex;
            align-self: center;
            justify-content: center;

            img {
                height: 32px;
            }
        }
    }

    button {
        width: 100%;
        margin-top: 20px;
        max-width: unset !important;
    }

    div.MuiDialogContent-root {
        padding-left: 0;
        padding-right: 0;
    }

    div.MuiPaper-root {
        margin-left: 15px;
        margin-right: 15px;
    }

    // .MuiDialog-container {
    //   padding-bottom: 55px;
    //   @include breakpoint('sm') {
    //     padding-bottom: 0;
    //   }
    // }

    .MuiDialogActions-root {
        width: 100vw;
        position: fixed;
        text-align: center;
        bottom: 20px;
        left: 0;
        right: 0;
        height: 0;
        button {
            max-width: none;
            margin: 0;
            width: 100%;
        }
        @include breakpoint('sm') {
            position: relative;
            bottom: auto;
            left: auto;
            right: auto;
            top: auto;
            width: auto;
            button: {
                max-width: 230px;
                width: auto;
            }
        }
    }

    button {
        width: 100%;
    }

    .MuiBackdrop-root {
        background: none;
    }

    .MuiDialog-paper {
        overflow: initial;
        background: $darkgreen;
        box-shadow: none;
    }

    .content-text {
        margin-top: 2rem;
        text-align: center;
        font-family: 'maisonneue-book';
        color: $darkgreen;
        font-weight: 500;
        font-size: 16px;
    }

    .content {
        background: white;
        padding: 30px 20px;
        margin-top: 22px;
        border-radius: 8px;
        font-size: 18px;
        color: $darkgreen;
        font-size: 16px;
        width: 100%;
        a {
            color: $darkgreen;
        }

        > div:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    .header1 {
        color: white;
        font-size: 28px;
        text-align: center;
        width: 100%;
        margin-top: 10px;
    }
}
