.chatting-with {
    padding-left: 20px;
    margin-top: 35px;
    padding-bottom: 10px;
    @media (min-width: 1200px) {
        padding-left: 30px;
    }

    h2 {
        margin-bottom: 0;

        &.user-header {
            margin-top: 0;
        }
    }
}
