div.chat-block,
div.chat-block__wrapper {
    margin: 0;
    padding: 0;
}

.chat-block {
    width: 100%;

    .chat-block__wrapper {
        .chat__grid {
            flex-direction: column;
        }

        .preview__container {
            width: 100%;
            border-left: 2px solid #e6e8ec;
            border-right: 2px solid #e6e8ec;
            padding: 0 8px 8px 8px;
        }

        margin: 20px;
        margin-top: 0;
        @media (min-width: 1200px) {
            margin: 30px;
            margin-top: 0;
        }

        input[type='file'] {
            display: none;
        }

        .chat-block__text-area-container {
            border: 2px solid #e6e8ec;
            border-bottom: 0;
            border-radius: 6px 6px 0 0;
            padding: 10px;
            margin-top: 0;

            textarea {
                font-size: 12px;
                resize: none;
                outline: none;
                border: 0;
                width: 100%;
                font-family: 'Inter';
            }
        }
    }
}
