.game-snake-block {
    position: relative;
    width: 225px;
    height: 225px;
    margin: 25px auto 20px auto;

    @mixin game-snake-mixin {
        h2 {
            color: white;
            margin-top: 0;
            font-size: 30px;
        }

        button.btn--ghost {
            padding: 0;
            height: 37px;
            width: 159px;
            margin-left: auto;
            margin-right: auto;
            font-size: 12px;
            margin-bottom: 0;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
            border: 0;
        }
    }

    #can {
        background-color: #003d3b;
        border-radius: 10px;
        @include game-snake-mixin;
    }

    .game-snake-block__game-over {
        text-align: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include game-snake-mixin;

        .close {
            position: absolute;
            top: -14px;
            right: -14px;
            background-color: white;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                opacity: 0.7;
            }
            svg {
                width: 11px;
                height: 11px;
            }
        }
    }
}
