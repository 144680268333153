@import '../../assets/style/_breakspoints.scss';

.patient-right-panel__container {
    display: none;
    @include breakpoint('sm') {
        display: initial;
    }
}

.right-block {
    border-radius: 20px;
    padding: 32px;
    background: white;
    margin: 0 16px 0 16px;
    min-width: calc(100vw - 32px);
    border: 1px solid #e6e8ec;

    @include breakpoint('sm') {
        margin: 32px 16px;
        min-width: auto;
    }
}
