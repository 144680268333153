@import '../../../../assets/style/_fonts.scss';
@import '../../../../assets/style/_colors.scss';

.message-info-block {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 25px;
    color: #b1b5c3;
    font-family: $mosh-font-family;
    text-transform: uppercase;
    font-size: 10px;

    > div {
        flex-direction: row-reverse;
    }
}

.message-block {
    text-align: right;

    .message-block__container {
        padding: 15px;
        font-size: 12px;
        border-radius: 16px;
        display: inline-flex;
        align-items: center;
        margin: 0.3rem 0 0;
        word-break: break-word;
        background-color: #f4f5f6;
        hyphens: auto;
        white-space: pre-wrap;
    }

    &.message-block--local-participant {
        text-align: left;

        .message-block__container {
            background-color: $darkgreen;
            color: white;

            a {
                color: white !important;
            }
        }
    }
}
