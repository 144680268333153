@import '../../assets/style/breakspoints';
@import '../../assets/style/_colors.scss';
@import '../../assets/style/_fonts.scss';

.screen-share-banner-block.MuiGrid-container {
    position: absolute;
    z-index: 8;
    // padding:1.43em;

    // justify-content: flex-end;
    background-color: rgba(255, 255, 255, 1);
    width: 320px;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: auto !important;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    height: 29px;

    // @include breakpoint('sm') {
    //   bottom: 0;
    // }

    h6 {
        color: #000;
        font-size: 12px;
        flex-grow: 1;
        text-align: center;
    }

    button {
        height: 24px;
        font-size: 12px;
        background-color: #de0000 !important;
        color: white;
        margin: 0 2rem;
        align-self: center;
        justify-content: center;
        display: flex !important;
        font-size: 9px !important;
        margin: 0;
        // padding: 16px 20px;
        border-radius: 0 0 10px 0;
        padding: 0;
        padding-left: 13px;
        height: 100%;
        width: 125px;
        position: relative;
        &::before {
            content: '';
            width: 7px;
            height: 7px;
            position: absolute;
            top: 0;
            left: 12px;
            bottom: 0;
            margin: auto;
            background-color: white;
        }
        &:hover {
            color: white;
            background-color: #600101;
        }
    }
}

.footer-block {
    background-color: transparent;
    bottom: 0;
    left: 0;
    right: 0;
    height: 72px;
    position: fixed;
    display: flex;
    padding: 0;
    z-index: 10;
    height: auto;

    @include breakpoint('sm') {
        position: absolute;
    }
    .placeholder {
        flex: 1;
    }

    @include breakpoint('sm') {
        height: 72px;
        bottom: 24px;
        padding: 0 1.43em;
    }

    .footer-block__control_bar {
        display: flex;
        transform: scale(0.8);
        min-width: unset;
        padding-left: 0;
        padding-right: 0;
        max-width: 310px;
        padding-bottom: 10px;
        width: 100vw;

        @include breakpoint('md') {
            width: auto;
        }

        @media (min-width: 960px) {
            transform: scale(0.8);
            min-width: 270px;
        }
        @media (min-width: 1200px) {
            min-width: 350px;
        }
    }

    .footer-block__option-bar {
        width: 100vw;
        display: flex;
        flex-direction: row;
        background-color: $darkgreen;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-around;

        &.red {
            background-color: #ffccc7;
        }

        @include breakpoint('sm') {
            display: none;
        }

        > div {
            flex-grow: 0;
            flex: 0;
        }

        .btn-rebook__container {
            width: 100px;

            // Rebook button
            .btn-rebook {
                margin: 15px auto;
                padding: 0;
                width: 100px;
                height: 28px;
                font-size: 12px;
                border-radius: 8px;
            }
        }

        .footer-block__patient_info {
            align-self: center;
            color: white;
            text-decoration: underline;
            font-size: 10px;
            text-align: right;

            > div {
                padding-right: 5px;
            }
        }

        .footer-block__patient_info,
        div.time_label {
            flex-grow: 1;
        }

        &.option_bar_user {
            .footer-block__patient_info,
            .btn-rebook__container {
                display: none;
            }
        }
    }
}

.time-remaining--desktop {
    display: none;
    @include breakpoint('sm') {
        display: initial;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.03);
    }
}

.footer-block__time-label {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    font-size: 10px;
    font-family: 'MaisonNeueBook' !important;
    text-align: left;
    justify-content: flex-start;
    color: white;
    display: flex;
    border-radius: 8px;
    flex-grow: 1 !important;
    > div {
        @include breakpoint('md') {
            min-width: 140px;
        }
    }

    &.red {
        background-color: #de0000 !important;
        animation-name: pulse;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-play-state: running;

        > div {
            @include breakpoint('md') {
                min-width: auto !important;
            }
        }

        span {
            display: none !important;
        }
    }

    &.mobile {
        background-color: transparent !important;
    }

    @include breakpoint('sm') {
        flex-grow: 0 !important;
    }

    > div {
        align-self: center;
    }

    img {
        width: 26px;
        height: 26px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: -2px;
    }

    .mins-remaining {
        display: none;
    }

    @media (min-width: 768px) {
        background-color: rgba(179, 212, 178, 54%);
        font-family: $mosh-font-family !important;
        width: auto;
        .mins-remaining {
            display: inline;
        }
        .mins-left {
            display: none;
        }
    }
    @media (min-width: 960px) {
        height: 55px;
        font-size: 12px;
        min-width: 120px;
        padding-right: 16px;
        img {
            width: 25px;
            height: 25px;
            margin-left: 6px;
        }
    }
    @media (min-width: 1200px) {
        height: 55px;
    }

    &.time {
        @media (max-width: 959px) {
            width: 100%;
            font-size: 12px;
            justify-content: center;
            padding-right: 15px;
            img {
                width: 35px;
                height: 35px;
            }
        }
    }
}
