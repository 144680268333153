@import '../../assets/style/colors';

.navbar-block {
    flex-grow: 1;

    .inner-block {
        background-color: white;

        .logo {
            flex-grow: 1;
            display: flex;
            align-self: center;

            img {
                height: 32px;
            }
        }

        .avatar {
            background-color: $darkgreen;
        }
    }
}
