@import '../../assets/style/colors';
@import '../../assets/style/breakspoints';

.preview-popup--file {
    .MuiPaper-root {
        width: calc(100vw - 64px);
        height: calc(100vh - 64px);
        max-width: none;

        .preview-image__embed {
            width: 100%;
            height: 100%;
        }
    }
}
.preview-image__container {
    position: relative;
    display: inline-flex;

    .close {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 9999;
        svg {
            width: 11px;
            height: 11px;
        }
        display: flex;

        @include breakpoint('sm') {
            display: none;
        }
    }

    &:hover {
        .close {
            display: flex;
        }
    }

    .preview-img,
    .preview-file {
        height: 80px;
        width: 80px;

        border-radius: 8px;

        transition: all 0.3s;
        &:hover {
            filter: brightness(0.4);
            cursor: pointer;
        }
    }

    .preview-file {
        color: white;
        justify-content: center;
        display: flex;
        word-break: break-word;
        text-align: center;
        align-self: center;
        flex-direction: column;
        background-color: $green;
        padding: 8px;
    }
}
