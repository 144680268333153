@import '../../assets/style/colors';
@import '../../assets/style/breakspoints';

.intro-block {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $green;
    opacity: 0.9;

    .intro-block__logo {
        position: fixed;
        top: 0;
        left: 0;
        text-align: center;
        width: 100%;
        margin: 1rem 0;

        img {
            height: 32px;
        }
    }

    .intro-block__container {
        position: relative;
        flex: 1;
        padding: 110px 0 80px 0;

        .intro-block__inner-container {
            display: block;
            height: auto;
            width: calc(100% - 40px);
            margin: auto;
            max-width: 400px;
            position: relative;

            @include breakpoint('sm') {
                max-width: 50vw;
                display: flex;
                border-radius: 8px;
                margin: auto;
            }

            .intro-block__doctor-hero-container {
                width: 100%;
                text-align: center;
                position: absolute;
                top: -2.5rem;
                left: 0;

                .intro-block__doctor_hero {
                    width: 90px;
                    height: 90px;
                    @media (min-width: 960px) {
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }

        .intro-block__content {
            background: white;
            width: 100%;
            border-radius: 8px;
            padding: 2rem;
            flex: 1;
        }
    }
}
