@import '../../assets/style/breakspoints';
@import '../../assets/style/mixins';

.panel-block {
    position: relative;

    .panel-block__drawer-dragger {
        display: initial;
        @include breakpoint('sm') {
            display: none;
        }
    }

    .panel-block__wrapper {
        @include outer-container;
        padding: 32px;
        background-color: white;
        margin: 16px;
        height: 50vh;
        @include breakpoint('sm') {
            margin: 32px 16px;
            height: 100%;
        }

        .panel-block__patient-info-panel {
            text-align: center;
            margin-bottom: 5px;
            margin-left: 0;
            @media (min-width: 960px) {
                margin-bottom: 25px;
            }

            div {
                font-size: 14px;
                @media (min-width: 960px) {
                    font-size: 16px;
                }
            }

            h1 {
                margin-top: 5px;
                @media (max-width: 959px) {
                    font-size: 30px;
                }
                @media (min-width: 960px) {
                    margin-top: 15px;
                }
            }
        }

        .btn-rebook-container {
            text-align: right;
            @media (max-width: 959px) {
                display: none;
            }
        }
    }
}

.doctor-note-section--mobile {
    display: initial;
    @include breakpoint('sm') {
        display: none;
    }
}

.swipe-drawer-block {
    //display: initial;
    //@include breakpoint('sm') {
    //  display: none;
    //}
    .MuiDrawer-paper {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.main-block {
    display: none;
    @include breakpoint('sm') {
        display: initial;
    }
}

div[class^='MuiTabs'] {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
        background-color: transparent;
        color: $darkgreen;
        letter-spacing: 0;
        text-transform: none;
        font-size: 16px;
        border-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        display: inline-block;
        width: auto;
        min-width: unset;
        margin-right: 15%;
        color: rgba(0, 0, 0, 10%);
        @media (min-width: 960px) {
            font-size: 16px;
            &:hover {
                background-color: transparent;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    span.MuiTabs-indicator {
        height: 3px;
        border-radius: 5px;
    }
}
