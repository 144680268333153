button[class^='makeStyles-deviceButton'] {
    background-color: #e9e9e9 !important;

    &:hover {
        background-color: #e1dddd !important;
    }
}

div[class^='makeStyles-localPreviewContainer'] {
    padding-right: 0 !important;
}
