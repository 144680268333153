/// Mixin to manage responsive breakpoints

$breakpoints: (
    'xs': 400px,
    'sm': 960px,
    //tablet portrait
    'md': 1024px,
    // tablet landscape
    'lg': 1024px,
    // desktop
    'xl': 1200px,
    // larger desktops
) !default;

@mixin breakpoint($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}
