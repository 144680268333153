// Brand colors
$darkgreen: #003d3b;
$green: #82c780;
$green_50: #badcb9;
$green_med: #bbdeb6;
$green_light: #daeed9;
$green_xlight: #e7f3e6;

$orange: #ff6340;

$sand: #ebebd4;
$sand_light: #f5f5ea;
$sand_xlight: #f9f9f3;

// Functional
$red: #de0000;
$blue: #7ae5e5;
$purple: #8c368f;
$yellow: #fff07d;
