@import '../../assets/style/breakspoints';

.main-participant-info {
    position: relative;
    display: flex;
    align-items: center;

    &.full-width {
        grid-area: 1/ 1/ 3/ 3;
        @include breakpoint('sm') {
            grid-area: none;
            position: static;
        }
        @include breakpoint('md') {
            grid-area: 1/ 1/ 2/ 3;
        }
    }

    .main-participant-info__container {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;

        > div {
            display: flex;
        }
    }

    .main-participant-info__recording-indicator {
        position: absolute;
        bottom: auto;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 0.1em 0.3em 0.1em 0;
        font-size: 1.2rem;
        height: 28px;
        right: 0;
        top: 0;

        @include breakpoint('sm') {
            bottom: 0;
            right: auto;
            top: auto;
        }

        .circle {
            height: 12px;
            width: 12px;
            background: red;
            border-radius: 100%;
            margin: 0 0.6em;
            animation: 1.25s pulsate ease-out infinite;
        }
    }

    .main-participant-info__avatar-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 64, 60, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;

        svg {
            transform: scale(2);
        }
    }

    .main-participant-info__reconnecting-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(40, 42, 43, 0.75);
        z-index: 1;

        .content {
            color: white;
        }
    }
}

@keyframes pulsate {
    0% {
        background: #a90000;
    }
    50% {
        background: #f00;
    }
    100% {
        background: #a90000;
    }
}
