@import '../../../assets/style/colors';

.unread-notification {
    width: 13px;
    height: 13px;
    background-color: $orange;
    border-radius: 50%;
    position: absolute;
    right: 1px;
    top: 1px;
    opacity: 1;
    transition: opacity 2ms ease-in;
    z-index: 999;
    animation: notification 1.25s infinite;
}
