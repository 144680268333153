@import '../../assets/style/breakspoints.scss';

.chat-window__container {
    margin-left: 20px;
    @include breakpoint('sm') {
        display: none;
    }
}

@media (min-width: 960px) {
    // .chat-window__container {
    //   min-height: calc(82vh + 26px);
    // }

    .video_container > div {
        height: calc(100vh - 128px);
        max-height: 37vw;
        min-height: 400px;
    }

    .MuiGrid-grid-md-12 .video_container > div {
        max-height: none;
    }
}

@media (min-width: 960px) {
    div[class^='makeStyles-chatWindowContainer'] {
        margin-left: 0;
    }

    .MuiGrid-grid-md-8 div.video_block {
        margin-right: 0;
    }
}

div[class^='makeStyles-chatWindowContainer'] {
    border-radius: 20px;
    @media (min-width: 960px) {
        border: 1px solid #e6e8ec;
    }
}
