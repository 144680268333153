@import '../../../assets/style/_breakspoints.scss';
@import '../../../assets/style/_colors.scss';
@import '../../../assets/style/_fonts.scss';

.apology-dialog {
    .apology-dialog__icon {
        text-align: center;
        margin-top: 40px;
    }

    .apology-dialog__content {
        text-align: center;
        font-family: $font-clear-face;
        color: $darkgreen;
        font-weight: 500;
        font-size: 24px;
        margin-top: 35px;
        letter-spacing: -0.02em;
        max-width: 29ch;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
    }

    .apology-dialog__actions {
        justify-content: center;
    }
}

h2.user_name {
    font-size: 27px;
    margin-top: 35px;
    text-align: center;
    font-family: $font-clear-face;
    color: $darkgreen;
    font-weight: 500;
    letter-spacing: -0.02em;
    @include breakpoint('md') {
        font-size: 35px;
        margin-top: 55px;
    }
}

@mixin btn-video-preview {
    width: 45px;
    height: 45px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.video-preview__container {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;

    .identity {
        padding: 3px;
        padding-right: 5px;
        padding-left: 8px;

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .btn-mobile-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin: 1.5em 0 1em;
        z-index: 2;

        button {
            @include btn-video-preview;
            margin: 0 0.5em;
        }
    }

    .video-preview {
        > div {
            background: transparent;

            video {
                border-radius: 10px;
            }
        }
    }

    .video-preview__buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;

        button {
            @include btn-video-preview;
            margin: 1em 0.5em;
        }
    }
}

.game-block {
    text-align: center;
    display: none;
    margin-top: 1.5em;
    @include breakpoint('sm') {
        display: block;
    }
}

.link-device-test {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    font-size: 11px;
    margin-bottom: 30px;
    @media (min-width: 960px) {
        font-size: 12px;
    }

    &.link-device-test-doctor {
        margin-top: 25px;
    }
}

.consult-message {
    max-width: 45ch;
    margin: 20px auto 25px auto;
    text-align: center;
    font-size: 14px;
    color: $darkgreen;
}

.join-btn__container {
    &.hide {
        display: none;
    }
}
