@import '../../../assets/style/_colors.scss';

.btn-patient-options {
    .unread-notification {
        width: 13px;
        height: 13px;
        background-color: $orange;
        border-radius: 50%;
        position: absolute;
        right: -5px;
        top: -5px;
        opacity: 1;
        transition: opacity 2ms ease-in;
        z-index: 999;
        animation: notification 1.25s infinite;
    }
}

@keyframes notification {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
