@import './assets/style/_colors.scss';
@import './assets/style/_fonts.scss';
@import './assets/style/_breakspoints.scss';

.video-grid__container {
    //margin-left: 20px;
    //@include breakpoint('sm') {
    //  display: none;
    //}

    > .MuiGrid-container {
        height: calc(100vh - 64px);
        @include breakpoint('sm') {
            height: auto;
        }
    }
}

.swipe-drawer-block {
    display: initial;
    @include breakpoint('sm') {
        display: none;
    }
}

.doctor-note-section--desktop {
    display: none;
    @include breakpoint('sm') {
        display: initial;
    }
}

@font-face {
    font-family: 'Clearface';
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    src: url('./assets/fonts/ClearfaceITCPro-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'MaisonNeueBook';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/MaisonNeue-Book.otf') format('truetype');
}

@font-face {
    font-family: 'MaisonNeueDemi';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/MaisonNeue-Demi.otf') format('truetype');
}

@font-face {
    font-family: 'MaisonNeueBold';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/MaisonNeue-Bold.otf') format('truetype');
}

.video_container {
    background-color: black;
}

div.video_block footer {
    @media (min-width: 960px) {
        bottom: 7px;
    }
}
