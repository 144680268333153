@import '../../assets/style/_breakspoints.scss';

// Doctor note block
.note-block {
    margin: 0;
    width: calc(100vw - 96px);
    @include breakpoint('sm') {
        width: auto;
        padding: 5px 15px 15px 15px;
    }

    .divider {
        margin: 16px 0;
    }
}
