@import './_colors.scss';
@import './_breakspoints.scss';

button.MuiButtonBase-root,
input[type='submit'],
input[type='button'] {
    padding: 9px 35px;
    display: inline-block;
    white-space: normal;
    text-decoration: none;
    background-color: $orange;
    border: 1px solid transparent;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: none;

    &:hover {
        box-shadow: none;
    }

    &.btn--reg {
        letter-spacing: 0;
        text-transform: none;
        font-size: 14px;
        padding: 10px 20px;
        color: $darkgreen;
        @media (min-width: 960px) {
            font-size: 15px;
        }

        .MuiButton-label span {
            display: none;
        }
    }

    &.btn--green {
        background-color: $green;
        color: $darkgreen;

        &:hover {
            background-color: $green;
        }
    }

    &.btn--orange {
        background-color: $orange;
        color: $sand;

        &:hover {
            background-color: lighten($orange, 50);
        }
    }

    &.btn--ghost {
        background-color: #fff;
        color: $darkgreen;
        border: 1px solid $darkgreen;

        &:hover {
            background-color: #fff;
        }
    }

    &.btn--lrg {
        padding: 18px 30px;
    }

    &.full-width {
        width: 100%;
        display: block;
        max-width: unset;
    }

    &.btn-patient-options {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }

    @media (min-width: 960px) {
        &:hover {
            background-color: $orange;
            opacity: 0.7;
        }
    }
}

// Shadowed button

.btn--shadowed {
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.btn--shadowed::before {
    content: 'Okay';
    border-radius: 0;
    white-space: normal;
    text-decoration: none;
    background-color: #ff6340;
    border: 1px solid transparent;
    color: white;
    cursor: pointer !important;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-size: 14px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    font-family: 'MaisonNeueDemi';
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.btn--shadowed::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateX(10px) translateY(10px);
    z-index: 1;
    border-radius: 10px;
}

.btn--shadowed,
.btn--shadowed::before,
.btn--shadowed::after {
    width: 170px;
    height: 42px;
}

.btn--shadowed::after {
    border: 1px solid #003d3b;
    background-color: white;
}

div[class^='MuiDialog'] .btn--shadowed {
    margin-bottom: 40px;
}

button.btn-save-notes {
    margin-bottom: 15px;
    @media (min-width: 960px) {
        margin-bottom: 0;
    }
    @media (max-width: 959px) {
        width: 100%;
    }
}

// File send button
.btn--file-send__container {
    display: flex;
    padding: 8px;
    justify-content: right;
    background: $darkgreen;
    width: 100%;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .btn--file-send__attachment-container {
        position: relative;
        margin-right: 1rem;

        .spinner {
            color: white;
            position: absolute;
            top: 2.5px;
            left: 2px;
            margin-top: -12;
            margin-left: -12;
        }
    }

    @include breakpoint('sm') {
        margin: 0;
        width: 100%;
    }

    button {
        height: 28px;
        padding: 0;
    }

    button.btn--send {
        background-color: $green;
        width: 75px;
        padding: 0;
        font-size: 14px;
        display: flex;

        &:disabled {
            background-color: $green;
            opacity: 0.4;
        }
    }

    button.btn--file-send {
        border-radius: 50%;
        width: 28px;
        background-color: white;
        min-width: auto;

        &:disabled {
            background: grey;

            & path {
                fill: #d8d8d8;
            }
        }

        span {
            display: flex;

            svg {
                width: 20px;
                height: 20px;
            }

            path {
                fill: $darkgreen;
            }
        }
    }
}

button.MuiIconButton-root {
    padding: 0 !important;
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.4) !important;
    transition: 0s;

    svg {
        width: 26px;
        height: 26px;
        color: white;
    }

    &.btn-chat-mobile {
        @media (min-width: 960px) {
            display: none;
        }
    }

    @media (min-width: 960px) {
        &:hover {
            background-color: rgba(255, 255, 255, 0.5) !important;
            box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
        }
        width: 45px;
        height: 45px;
        svg {
            width: 22px;
            height: 22px;
        }
    }
    @media (min-width: 1200px) {
        width: 56px;
        height: 56px;
        svg {
            width: 26px;
            height: 26px;
        }
    }

    &.end_call_btn {
        background-color: #de0000 !important;
        @media (min-width: 960px) {
            &:hover {
                background-color: #eb0000 !important;
            }
        }

        svg {
            transform: rotate(135deg);
        }
    }
}

// Logout button
.btn--logout {
    position: absolute;
    right: 6px;
    @media (min-width: 960px) {
        right: 20px;
    }
    cursor: pointer;

    button {
        text-transform: none;
        padding-left: 0;

        &:hover {
            background: none !important;
        }
    }

    &:hover {
        opacity: 0.7;
    }
}

// Screen share button
.btn--logout {
    position: absolute;
    right: 6px;
    @media (min-width: 960px) {
        right: 20px;
    }
    cursor: pointer;

    button {
        text-transform: none;
        padding-left: 0;

        &:hover {
            background: none !important;
        }
    }

    &:hover {
        opacity: 0.7;
    }
}
