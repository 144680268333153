@import '../../assets/style/colors';

.drawer-dragger {
    width: 100vw;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-self: center;
    top: 0;
    left: 0;
    z-index: 999;

    .drawer-dragger__bar {
        width: 20vw;
        height: 4px;
        border-radius: 8px;
        background-color: $darkgreen;
        margin: 8px 0;
    }
}
